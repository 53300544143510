<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <el-icon>
            <menu />
          </el-icon>
          User Logs
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="container">
      <div class="handle-box">
        <el-select
          v-model="query.tag"
          placeholder="Tag"
          class="handle-select mr10"
          :clearable="true"
        >
          <el-option label="New" value="NEW"></el-option>
          <el-option label="Changed" value="CHANGED"></el-option>
          <el-option label="Deleted" value="DELETED"></el-option>
          <el-option label="Sign In" value="USER_SING_IN"></el-option>
          <el-option label="Sign Out" value="USER_SING_OUT"></el-option>
          <el-option
            label="System exception"
            value="SystemException"
          ></el-option>
        </el-select>
        <el-select
          v-model="query.logLevel"
          placeholder="Log Level"
          class="handle-select mr10"
          :clearable="true"
        >
          <el-option label="User" value="User"></el-option>
          <el-option label="System" value="System"></el-option>
        </el-select>
        <el-date-picker
          type="date"
          placeholder="Start Time"
          v-model="query.startCreatedTime"
          class="handle-input"
          style="margin-right: 10px"
          :clearable="true"
        ></el-date-picker>
        <el-date-picker
          type="date"
          placeholder="End Time"
          v-model="query.endCreatedTime"
          class="handle-input"
          style="margin-right: 10px"
          :clearable="true"
        ></el-date-picker>
        <el-input
          v-model="query.username"
          placeholder="Username"
          class="handle-input mr10"
          :clearable="true"
        ></el-input>
        <el-input
          v-model="query.keyword"
          placeholder="keyword"
          class="handle-input mr10"
          :clearable="true"
        ></el-input>
        <el-button type="primary" @click="handleSearch">
          <el-icon>
            <search />
          </el-icon>
          Search
        </el-button>
      </div>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @sort-change="changeSort"
        :default-sort="{ prop: 'LogId', order: 'descending' }"
      >
        <el-table-column
          prop="logId"
          label="LogId"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="LogId"
          :width="this.$widthRatio * 100 + 'px'"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="username"
          label="Username"
          :width="this.$widthRatio * 100 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="tag"
          label="Tag"
          :width="this.$widthRatio * 160 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="logLevel"
          label="logLevel"
          :width="this.$widthRatio * 120 + 'px'"
        ></el-table-column>
        <el-table-column
          prop="createdTime"
          sortable="true"
          :sort-orders="['ascending', 'descending', null]"
          :sort-by="CreatedTime"
          label="Log time"
          :label-width="this.$widthRatio * 200 + 'px'"
        ></el-table-column>
        <el-table-column prop="message" label="Message"></el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="itemTotal"
          :page-count="pageTotal"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "../../service/api";

export default {
  name: "userLogs",
  data() {
    return {
      controllerUrl: "/userLog",
      query: {
        tag: null,
        logLevel: null,
        username: null,
        keyword: null,
        startCreatedTime: null,
        endCreatedTime: null,
        orderBy: "LogId",
        orderDirection: "desc",
        pageIndex: 1,
        pageSize: 20,
      },
      tableData: [],
      multipleSelection: [],
      delList: [],
      editVisible: false,
      itemTotal: 0,
      pageTotal: 0,
      form: {},
      idx: -1,
      id: -1,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getData(this.controllerUrl, this.query).then((res) => {
        let resData = res.result;
        this.tableData = resData.list;
        this.query.pageIndex = resData.pageIndex;
        this.query.pageSize = resData.pageSize;
        this.itemTotal = resData.count;
        this.pageTotal = Math.ceil(resData.count / resData.pageSize);
      });
    },
    handleSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    changeSort(sortData) {
      console.log(sortData);
      if (sortData.order) {
        this.query.orderBy = sortData.prop;
        this.query.orderDirection =
          sortData.order === "ascending" ? "asc" : "desc";
      } else {
        this.query.orderBy = "";
        this.query.orderDirection = "";
      }
      this.loadData();
    },
    handlePageChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
  },
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}

.handle-select {
  width: 120px;
}

.handle-input {
  width: 200px;
  display: inline-block;
}
.table {
  width: 100%;
  font-size: 1.17rem;
}
.red {
  color: #ff0000;
}
.mr10 {
  margin-right: 10px;
}
.table-td-thumb {
  display: block;
  margin: auto;
  width: 40px;
  height: 40px;
}
</style>
